import { Resource } from 'src/app/shared/stores/resource-store/resource.model';
import { GROUPING_OPTIONS, SCHEDULE_DATERANGE_TYPES, SHOW_OPTIONS } from '../../schedule-helpers/enums';
import { ScheduleActivity } from '../schedule-activity-store/schedule-activity.model';
import { ScheduleRequestParameters } from './schedule-request-parameters.model';

export const STATUS_NOT_REQUIRED: number = 5;

export interface Schedule {
    organizationUnitId: Array<number>;
    startDate: string;
    endDate: string;
}

export interface ExtendedScheduleActivity extends Omit<ScheduleActivity, 'id' | 'resourceId'> {
    id: string | number;
    resourceId: string | number;
    name?: string;
    originalId?: string | number;
    originalResourceId?: string | number;
    height?
}

export interface ScheduleShowOption {
    value: SHOW_OPTIONS;
    state: boolean;
}

export interface MultisortingOption {
    field: string;
    ascending: boolean;
}

export interface ResourcesAndActivities {
    activitiesForActivityPanel: Map<number, ScheduleActivity>;
    activitiesForResourcePanel: Map<number, ScheduleActivity>;
    resources: Array<Resource>;
}

export interface ScheduleFilterSettings {
    requestParameters: ScheduleRequestParameters;
    allOrganizationUnitsSelectedState: boolean;
    showOptions: Array<ScheduleShowOption>;
    activityShowOptions: Array<ScheduleShowOption>;
    activityTypeIds: Array<number>;
    activityTypeIdsForActivities: Array<number>;
    sortedActivityTypeRootIdsForActivities: Array<number>;
    applyFilterOnResourceScheduleState: boolean;
    applyFilterOnActivityScheduleState: boolean;
    groupingOptionType: GROUPING_OPTIONS;
    daterangeType: SCHEDULE_DATERANGE_TYPES;
    daypartIds: Array<number>;
    savedBaseResourceTypes: Array<SavedEntityListForSchedule>;
    savedExtraResourceTypes: Array<SavedEntityListForSchedule>;
    savedOrganizationUnits: Array<SavedEntityListForSchedule>;
    savedResourceProperties: Array<SavedEntityListForSchedule>;
    savedMultisortingOrder: Array<MultisortingOption>;
    slotWidth: number;
    hideEmptyRows: boolean;
    splitterPosition: number;
}

export interface SavedEntityListForSchedule {
    id: number;
    displayName: string;
}

export interface ScheduleResourceEventDialog {
    activityId: number;
    activities: Array<ScheduleActivity>;
    loaded: boolean;
}

export interface PreviousRequestParameters {
    organizationUnitIds: Array<number>;
    resourceTypeIds: Array<number>,
    start: string,
    end: string,
    fillActivity: boolean;
    daypartIdsSelected: Array<number>;
    activityTypeIdsForActivity: Array<number>;
    activityTypeIdsForResource: Array<number>;
    showActivityPanel: boolean;
    showResourcePanel: boolean;
}
