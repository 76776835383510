import { Injectable } from '@angular/core';
import { Observable, combineLatest, of, throwError } from 'rxjs';
import { catchError, finalize, first, map, tap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import moment from 'moment';

import { FilterSettingService } from 'src/app/shared/stores/filter-settings-store/filter-setting.service';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ResourcePropertyLanguageQuery } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.query';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { ResourceService } from 'src/app/shared/stores/resource-store/resource.service';
import { Resource } from 'src/app/shared/stores/resource-store/resource.model';
import { ErrorDialogService } from 'src/app/shared/services/error-dialog.service';
import { GlobalSettingsQuery } from 'src/app/shared/stores/global-settings/global-settings.query';
import { UtilsService } from 'src/app/shared/services/utils.service';

import { ScheduleActivityService } from '../schedule-activity-store/schedule-activity.service';
import { Activity, GetActivityResponse, GetScheduleActivityResponse } from '../schedule-activity-store/schedule-activity.model';
import { GROUPING_OPTIONS, SCHEDULE_DATERANGE_TYPES, SCHEDULE_NAVIGATION_OPTIONS } from '../../schedule-helpers/enums';
import { ScheduleMissingEntitiesService } from '../../schedule-helpers/schedule-missing-entities.service';
import { ScheduleRequestParameters } from './schedule-request-parameters.model';
import { MultisortingOption, PreviousRequestParameters, ScheduleShowOption } from './schedule.model';
import { ScheduleQuery } from './schedule.query';
import { ScheduleState, ScheduleStore } from './schedule.store';

@Injectable({
    providedIn: 'root'
})
export class ScheduleService {
    private readonly refreshTimerInterval: number = 15 * 60 * 1000; // 15 minutes
    private refreshTimer: any;
    private ongoingRequestsCount = 0;

    constructor(
        protected scheduleStore: ScheduleStore,
        private readonly scheduleMissingEntitiesService: ScheduleMissingEntitiesService,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly scheduleQuery: ScheduleQuery,
        private readonly filterSettingService: FilterSettingService,
        private readonly resourceTypeQuery: ResourceTypeQuery,
        private readonly resourcePropertyLanguageQuery: ResourcePropertyLanguageQuery,
        private readonly resourceService: ResourceService,
        private readonly scheduleActivityService: ScheduleActivityService,
        private readonly errorDialogService: ErrorDialogService,
        private readonly http: HttpClient,
        private readonly globalSettingsQuery: GlobalSettingsQuery,
        private readonly utilsService: UtilsService,
    ) { }

    public getScheduleData(force = false): Observable<[Array<Resource>, GetScheduleActivityResponse]> {
        const parameters = this.scheduleQuery.getRequestParametersSync();
        const currentRequestParameters: PreviousRequestParameters = {
            organizationUnitIds: parameters.organizationUnitIds,
            resourceTypeIds: parameters.baseResourceTypeIds,
            end: parameters.endDate,
            start: parameters.startDate,
            fillActivity: parameters.fillActivity,
            daypartIdsSelected: parameters.daypartIdsSelected,
            activityTypeIdsForActivity: parameters.activityTypeIdsForActivity,
            activityTypeIdsForResource: parameters.activityTypeIdsForResource,
            showActivityPanel: parameters.showActivityPanel,
            showResourcePanel: parameters.showResourcePanel,
        }

        // Do not make a call when the dates aren't valid
        if (currentRequestParameters.end !== undefined && (new Date(currentRequestParameters.start) >= new Date(currentRequestParameters.end))) {
            return of([[], { activitiesTreeForActivity: [],  activitiesTreeForResource: []}]);
        }

        // We will not make a new call if the parameters of the call haven't changed
        if (!force) {
            const previousRequestParameters = this.scheduleQuery.getPreviousRequestParametersSync();
            if (this.utilsService.objectsValuesEqual(previousRequestParameters, currentRequestParameters)) {
                return of([[], { activitiesTreeForActivity: [],  activitiesTreeForResource: []}]);
            }
        }

        this.scheduleStore.updatePreviousRequestParameters(currentRequestParameters);
        this.updateLoadingState(true);

        const resourceCall = this.resourceService.getResources(
            {
                organizationUnitIds: parameters.organizationUnitIds,
                resourceTypeIds: parameters.baseResourceTypeIds,
                includeOrganizationUnitMemberships: true,
                includeResourceTypeMemberships: true,
                includeResourceProperties: true,
            }, 
            parameters.startDate, 
            parameters.endDate
        );

        const activityCall = this.scheduleActivityService.getActivities(
            {
                organizationUnitIds: parameters.organizationUnitIds,
                fillActivity: parameters.fillActivity,
                daypartIdsSelected: parameters.daypartIdsSelected,
                activityTypeIdsForActivity: parameters.activityTypeIdsForActivity,
                activityTypeIdsForResource: parameters.activityTypeIdsForResource,
                showActivityPanel: parameters.showActivityPanel,
                showResourcePanel: parameters.showResourcePanel
            }, 
            parameters.startDate, 
            parameters.endDate
        );
        const timeZone = this.globalSettingsQuery.getTimeZoneSync();
        
        clearInterval(this.refreshTimer);

        return combineLatest([
            resourceCall,
            activityCall
        ]) 
        .pipe(
            catchError((error) => {
                this.startRefreshTimer(this.scheduleQuery.getFilterSettingsPanelStatusSync(), true);
                this.updateLoadingState(false);

                return throwError(() => error);
            }),
            tap(([resources, response]) => {
                const activitiesForActivityPanel = [];
                response.activitiesTreeForActivity?.forEach( act => {
                    if (act !== null && act !== undefined) {
                        activitiesForActivityPanel.push({
                            ...act,
                            start: moment.utc(act.start).tz(timeZone).format(),
                            end: moment.utc(act.end).tz(timeZone).format(),
                            adjustedEnd: moment.utc(act.adjustedEnd).tz(timeZone).format(),
                            adjustedStart: moment.utc(act.adjustedStart).tz(timeZone).format()
                        })
                    }
                });
                
                const activitiesForResourcePanel = [];
                response.activitiesTreeForResource?.forEach( act => {
                    if (act !== null && act !== undefined) {
                        activitiesForResourcePanel.push({
                            ...act,
                            start: moment.utc(act.start).tz(timeZone).format(),
                            end: moment.utc(act.end).tz(timeZone).format(),
                            adjustedEnd: moment.utc(act.adjustedEnd).tz(timeZone).format(),
                            adjustedStart: moment.utc(act.adjustedStart).tz(timeZone).format()
                        })
                    }
                });
     
                this.updateLoadingState(false);
                this.scheduleStore.setResourcesAndActivities(resources, activitiesForActivityPanel, activitiesForResourcePanel);
                this.startRefreshTimer(this.scheduleQuery.getFilterSettingsPanelStatusSync(), true);
            })
        );
    }

    public getActivitiesForActivityId(activityId: number): Observable<Array<Activity>> {
        if (this.scheduleQuery.isActivityDetailsLoaded(activityId)){ 
            return of(this.scheduleQuery.getActivityDetailsForSelectedIdSync(activityId));
        }

        const params = new HttpParams()
            .set('includeActivityTree', 'true')
            .set('includeMemoText', 'true')
            .set('includeActivityTypeDetails', 'true')
            .set('includeRootActivityTypeDetails', 'true')
            .set('includeResourceDetails', 'true')
            .set('includeWorkloadHours', 'true')
            .set('includeResourceOrganizationUnit', 'true')
        ;

        const apiUrl = `/api/v1/Activities/${activityId}`;
        this.updateLoadingStateActivityDetailsDialog(true);
        this.ongoingRequestsCount++;
        
        return this.http.get<GetActivityResponse>(apiUrl, { params }).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);

                this.updateLoadingStateActivityDetailsDialog(false); 
                this.getScheduleData(true).pipe(first()).subscribe();

                return throwError(error);
            }),
            tap((response: GetActivityResponse) => {
                this.scheduleStore.updateActivityDialogDetails({ activityId, activities: response.activities as any, loaded: true});
            }),
            finalize(() => {
                this.ongoingRequestsCount--; 
                if (this.ongoingRequestsCount === 0) {
                    this.updateLoadingStateActivityDetailsDialog(false); 
                }
            }),
            map((response) => response.activities)
        );
    }
    
    public resetStore(): void {
        this.scheduleStore.set([]);
    }

    public updateCurrentActivityIdSelected(id: number): void {
        this.scheduleStore.updateCurrentActivityIdSelected(id);
    }

    public updateDatesParameters(numberOfDays: SCHEDULE_NAVIGATION_OPTIONS, add: boolean): void {
        this.scheduleStore.updateDatesParameters(numberOfDays, add);
    }

    public updateOrganizationUnitIdsParameter(ids: Array<number>): void {
        this.scheduleStore.updateOrganizationUnitIdsParameter(ids);
        const savedUnits = this.organizationUnitQuery.getOrganizationUnitsByIdsSync(ids);
        const savedEntities = savedUnits.map(unit => ({id: unit.id, displayName: unit.displayName}));
        this.scheduleStore.updateSavedOrganizationUnits(savedEntities);
    }
    public updateLoadingStateActivityDetailsDialog(loadingStateActivityDetailsDialog: boolean): void {
        this.scheduleStore.updateLoadingStateActivityDetailsDialog(loadingStateActivityDetailsDialog);
    }
    
    public updateBaseResourceTypeIdsParameter(ids: Array<number>): void {
        this.scheduleStore.updateBaseResourceTypeIdsParameter(ids);
        const savedResourceTypes = this.resourceTypeQuery.getResourceTypesByIdsSync(ids);
        const savedEntities = savedResourceTypes.map(type => ({id: type.id, displayName: type.displayName}));
        this.scheduleStore.updateSavedBaseResourceTypes(savedEntities);
    }

    public updateExtraResourceTypeIdsParameter(ids: Array<number>): void {
        this.scheduleStore.updateExtraResourceTypeIdsParameter(ids);
        const savedResourceTypes = this.resourceTypeQuery.getResourceTypesByIdsSync(ids);
        const savedEntities = savedResourceTypes.map(type => ({id: type.id, displayName: type.displayName}));
        this.scheduleStore.updateSavedExtraResourceTypes(savedEntities);
    }

    public updateResourcePropertyIdsParameter(ids: Array<number>): void {
        const savedProps = this.resourcePropertyLanguageQuery.getResourcePropertiesByIdsSync(ids);
        const savedEntities = savedProps.map(prop => ({id: prop.resourcePropertyId, displayName: prop.text}));
        this.scheduleStore.updateSavedResourceProperties(savedEntities);
    }

    public updateShowOption(option: ScheduleShowOption, checked: boolean): void {
        this.scheduleStore.updateShowOption(option, checked);
    }

    public updateAllOrganizationUnitsSelectedState(state: boolean): void {
        this.scheduleStore.updateAllOrganizationUnitsSelectedState(state);
    }

    public updateFilterSettingsPanelStatus(state: boolean): void {
        this.scheduleStore.updateFilterSettingsPanelStatus(state);
    }

    public updateActivityShowOption(option: ScheduleShowOption, checked: boolean): void {
        this.scheduleStore.updateActivityShowOption(option, checked);
    }

    public updateActivityTypeIds(ids: Array<number>): void {
        this.scheduleStore.updateActivityTypeIds(ids);
    }

    public updateApplyFilterOnActivityScheduleState(state: boolean): void {
        this.scheduleStore.updateApplyFilterOnActivityScheduleState(state);
    }
    
    public updateActivityTypeIdsForActivities(ids: Array<number>): void {
        this.scheduleStore.updateActivityTypeIdsForActivities(ids);
    }

    public updateSortedActivityTypeRootIdsForActivities(ids: Array<number>): void {
        this.scheduleStore.updateSortedActivityTypeRootIdsForActivities(ids);
    }

    public updateGroupingOptionType(type: GROUPING_OPTIONS): void {
        this.scheduleStore.updateGroupingOptionType(type);
    }

    public updateDaterangeType(type: SCHEDULE_DATERANGE_TYPES): void {
        this.scheduleStore.updateDaterangeType(type);
    }

    public updateDaypartIds(ids: Array<number>): void {
        this.scheduleStore.updateDaypartIds(ids);
    }
    
    public setSchedulesToEmpty(): void {
        this.scheduleStore.set(null);
    }

    public updateRequestParameters(parameters: ScheduleRequestParameters): void {
        this.scheduleStore.updateRequestParameters(parameters);
    }

    public updateMultiselectOrderState(values: Array<MultisortingOption>): void {
        this.scheduleStore.updateMultiselectOrderState(values);
    }

    public updateShowOptions(showOptions: Array<ScheduleShowOption>): void {
        this.scheduleStore.updateShowOptions(showOptions);
    }

    public updateActivityShowOptions(actShowOptions: Array<ScheduleShowOption>): void {
        this.scheduleStore.updateActivityShowOptions(actShowOptions);
    }

    public updateScheduleStartDate(startDate: Date): void {
        this.scheduleStore.updateScheduleStartDate(startDate);
    }

    public updateScheduleEndDate(endDate: Date): void {
        this.scheduleStore.updateScheduleEndDate(endDate);
    }

    public updateApplyFilterOnResourceScheduleState(state: boolean): void {
        this.scheduleStore.updateApplyFilterOnResourceScheduleState(state);
    }

    public updateHideEmptyRows(state: boolean): void {
        this.scheduleStore.updateHideEmptyRows(state);
    }

    public updateSplitterPosition(value: number): void {
        this.scheduleStore.updateSplitterPosition(value);
    }

    public updateLoadingState(state: boolean): void {
        this.scheduleStore.updateLoadingState(state);
    }

    public updateSlotWidth(slotWidth: number): void {
        this.scheduleStore.updateSlotWidth(slotWidth);
    }

    public updateScheduleStateOnFilterSettingChanged(state: ScheduleState['ui']): void {
        this.scheduleMissingEntitiesService.handleMissingEntities(state).pipe(first()).subscribe((response) => {
            if (this.scheduleQuery.getValue().ui.selectedFilterSettingId === response.filterId) {
                if (response.updatedFilterOrganizationUnits !== undefined) {
                    this.updateOrganizationUnitIdsParameter(response.updatedFilterOrganizationUnits);
                }
                if (response.updatedFilterBaseResourceTypes !== undefined) {
                    this.updateBaseResourceTypeIdsParameter(response.updatedFilterBaseResourceTypes);
                }
                if (response.updatedFilterExtraResourceTypes !== undefined) {
                    this.updateExtraResourceTypeIdsParameter(response.updatedFilterExtraResourceTypes);
                }
                if (response.updatedFilterResourceProperties !== undefined) {
                    this.updateResourcePropertyIdsParameter(response.updatedFilterResourceProperties);
                }
                if (response.updatedFilterOrganizationUnits !== undefined || 
                    response.updatedFilterBaseResourceTypes !== undefined ||
                    response.updatedFilterExtraResourceTypes !== undefined ||
                    response.updatedFilterResourceProperties !== undefined
                ) {
                    const updatedFilterSetting = this.scheduleQuery.getUpdatedScheduleFilterSetting();
                    this.filterSettingService.updateFilterSetting(updatedFilterSetting).pipe(first()).subscribe();
                }
            }
        });

        this.scheduleStore.updateScheduleState(state);
    }
    public updateSelectedFilterSettingId(id: number): void {
        this.scheduleStore.updateSelectedFilterSettingId(id);
    }
    
    public startRefreshTimer(filterSettingsPanelOpen: boolean, force = false): void {
        if (!filterSettingsPanelOpen || force) { // if filter setting panel is closed/hidden
            this.refreshTimer = setInterval(() => {
                this.getScheduleData(true).pipe(first()).subscribe();
            }, this.refreshTimerInterval);
        } else { //if not, we don't refresh the data
            clearInterval(this.refreshTimer);
            this.refreshTimer = null;
        }
    }  
}
