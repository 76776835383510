import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { NestedTreeService } from 'src/app/shared/services';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { DaypartQuery } from 'src/app/shared/stores/day-part-store/day-part.query';
import { FILTER_SETTING_TYPE, FilterSetting } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { OrganizationUnit } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ResourcePropertyLanguage } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.model';
import { ResourcePropertyLanguageQuery } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.query';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { UserInfoQuery } from 'src/app/shared/stores/user-info-store/user-info.query';

import { Daypart } from 'src/app/shared/stores/day-part-store/day-part.model';
import { ActivityType, ActivityTypeWithLevelIndication } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { Resource } from 'src/app/shared/stores/resource-store/resource.model';
import { GROUPING_OPTIONS, SCHEDULE_DATERANGE_TYPES, SHOW_OPTIONS } from '../../schedule-helpers/enums';
import { ScheduleHelperService } from '../../schedule-helpers/schedule-helper.service';
import { ScheduleActivity } from '../schedule-activity-store/schedule-activity.model';
import { ScheduleRequestParameters } from './schedule-request-parameters.model';
import {
    MultisortingOption,
    PreviousRequestParameters,
    ScheduleFilterSettings,
    ScheduleShowOption,
} from './schedule.model';
import { ScheduleState, ScheduleStore } from './schedule.store';

@Injectable({
    providedIn: 'root'
})
export class ScheduleQuery extends QueryEntity<ScheduleState> {
    private readonly isFullUser: boolean;

    constructor(
        protected store: ScheduleStore,
        private readonly userInfoQuery: UserInfoQuery,
        private readonly utilsService: UtilsService,
        private readonly daypartQuery: DaypartQuery,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly scheduleHelperService: ScheduleHelperService,
        private readonly activityTypeQuery: ActivityTypeQuery,
        private readonly filterSettingQuery: FilterSettingQuery,
        private readonly resourcePropertyLanguageQuery: ResourcePropertyLanguageQuery,
        private readonly resourceTypeQuery: ResourceTypeQuery,
        private readonly nestedTreeService: NestedTreeService,
    ) {
        super(store);
        this.isFullUser = this.userInfoQuery.getIsFullUserSync();
    }

    public getPreviousRequestParametersSync(): PreviousRequestParameters {
        return this.getValue().previousRequestParameters;
    }

    public getResourcesSync(): Array<Resource> {
        return this.getValue().resourcesAndActivities?.resources;
    }

    public getActivitiesForActivityPanelSync(): Map<number, ScheduleActivity> {
        return this.getValue().resourcesAndActivities?.activitiesForActivityPanel;
    }

    public getActivitiesForResourcePanelSync(): Map<number, ScheduleActivity> {
        return this.getValue().resourcesAndActivities?.activitiesForResourcePanel;
    }

    public getSelectedParentActivityTypesForActivities(selectedActivityTypeIds: Array<number>, sortByOrder: boolean = false, filterOnParent: boolean = false): Array<ActivityType> {
        const activityTypes = this.activityTypeQuery.getActivityTypesSync();
        const idToActivityTypeMap = new Map<number, ActivityType>();
        activityTypes.forEach(at => idToActivityTypeMap.set(at.id, at));

        const selectedActivityTypes = selectedActivityTypeIds.map(id => idToActivityTypeMap.get(id)).filter(at => at !== undefined);
        let allParentActivityTypes = [];
        
        if (filterOnParent) {
            const parentActivityTypesSet = new Set<ActivityType>();

            for (const selectedActivityType of selectedActivityTypes) {
                // add directly if it is a root
                if (selectedActivityType.parentId === null) {
                    parentActivityTypesSet.add(selectedActivityType);
                }
                // add parent if it is not a root (will skip duplicates)
                else {
                    const parentOfSelected = idToActivityTypeMap.get(selectedActivityType.parentId);
                    if (parentOfSelected) {
                        parentActivityTypesSet.add(parentOfSelected);
                    }
                }
            }
    
            allParentActivityTypes = Array.from(parentActivityTypesSet);
        } else {
            allParentActivityTypes = selectedActivityTypes;
        }

        if (sortByOrder) {
            allParentActivityTypes.sort((a, b) => {
                const indexA = selectedActivityTypeIds.indexOf(a.id);
                const indexB = selectedActivityTypeIds.indexOf(b.id);

                return indexA - indexB;
            });
        }

        return allParentActivityTypes;
    }

    public getScheduleActivitySync(rootId: number, activitiesForActivityPanel: boolean = false): ScheduleActivity {
        const activities = activitiesForActivityPanel 
            ? this.getValue().resourcesAndActivities.activitiesForActivityPanel
            : this.getValue().resourcesAndActivities.activitiesForResourcePanel;

        return activities.get(rootId);
    }

    public getFlattenedScheduleActivitySync(rootId: number): Array<ScheduleActivity> {
        const activity = this.getScheduleActivitySync(rootId, true);
        const flattenedActivity = this.nestedTreeService.flattenEntityTree(activity);

        return flattenedActivity;
    }

    public getExtraResourceTypeIdsSync(): Array<number> {
        return this.getValue().ui.requestParameters.extraResourceTypeIds;
    }

    public getBaseResourceTypeIdsSync(): Array<number> {
        return this.getValue().ui.requestParameters.baseResourceTypeIds;
    }

    public isActivityDetailsLoaded(id: number): boolean {
        const activityDetails = this.getValue().activityDialogDetails.find(act => act.activityId === id);

        return activityDetails ? activityDetails.loaded : false;
    }

    public getRequestParametersSync(): ScheduleRequestParameters {
        const ui = this.getValue().ui;
        const showOptions = this.getValidShowOptions(ui.showOptions);
        
        const applyActivityActivityFilter = ui.applyFilterOnActivityScheduleState;

        // NOTE: "fillActivity" is linked together between showOptions and activityShowOptions
        // Since they are linked and always have the same value, either one can be used
        const showFillActivity = this.getShowFillFromShowOptionsSync();
        const applyResourceActivityFilter = ui.applyFilterOnResourceScheduleState;
        const showActivityPanel = this.getShowActivityScheduleSync();
        const showResourcePanel = showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVE_PANEL)?.state;
        let activityTypeIdsForActivity = [];
        let activityTypeIdsForResource = [];


        if (showResourcePanel) {
            activityTypeIdsForResource = applyResourceActivityFilter ? ui.activityTypeIds : [];
        }

        if (showActivityPanel) {
            activityTypeIdsForActivity = applyActivityActivityFilter ? [...new Set(ui.activityTypeIdsForActivities?.map(id => this.activityTypeQuery.getRootParentIdOfActivityTypeById(id)).filter(id => id !== undefined))] : [];
        }

        return { 
            ...ui.requestParameters, 
            fillActivity: showFillActivity,
            daypartIdsSelected: ui.daypartIds,
            activityTypeIdsForActivity: activityTypeIdsForActivity,
            activityTypeIdsForResource: activityTypeIdsForResource,
            showActivityPanel: showActivityPanel,
            showResourcePanel: showResourcePanel
        };
    }

    public getDaterangeType(): Observable<SCHEDULE_DATERANGE_TYPES> {
        return this.select(state => state.ui.daterangeType);
    }

    public getDaterangeTypeSync(): SCHEDULE_DATERANGE_TYPES {
        return this.getValue().ui.daterangeType;
    }

    public getStartDate(): Observable<Date> {
        return this.select(state => moment(state.ui.requestParameters.startDate).toDate());
    }

    public getStartDateSync(): Date {
        return moment(this.getValue().ui.requestParameters.startDate).toDate();
    }

    public getEndDate(): Observable<Date> {
        return this.select(state => moment(state.ui.requestParameters.endDate).toDate());
    }

    public getEndDateSync(): Date {
        return moment(this.getValue().ui.requestParameters.endDate).toDate();
    }

    public getApplyFilterOnResourceScheduleState(): Observable<boolean> {
        return this.select(state => state.ui.applyFilterOnResourceScheduleState);
    }

    public getApplyFilterOnResourceScheduleStateSync(): boolean {
        return this.getValue().ui.applyFilterOnResourceScheduleState;
    }

    public getScheduleFiltersValiditySync(): boolean {
        const ui = this.getValue().ui;

        return ui.requestParameters.organizationUnitIds && ui.requestParameters.organizationUnitIds.length > 0 &&
            ui.requestParameters.baseResourceTypeIds && ui.requestParameters.baseResourceTypeIds.length > 0 &&
            ((ui.activityTypeIds && ui.activityTypeIds.length > 0) || 
            (ui.activityTypeIdsForActivities && !!ui.activityTypeIdsForActivities));
    }

    public getShowOptions(): Observable<Array<ScheduleShowOption>> {
        return this.select(state => state.ui.showOptions).pipe(
            map(showOptions => this.getValidShowOptions(showOptions))
        );
    }

    public getActivityShowOptions(): Observable<Array<ScheduleShowOption>> {
        return this.select(state => state.ui.activityShowOptions).pipe(
            map(actShowOptions => this.getValidActivityShowOptions(actShowOptions))
        );
    }

    public getShowOptionsSync(): Array<ScheduleShowOption> {
        return this.getValidShowOptions(this.getValue().ui.showOptions);
    }

    public getShowActivityScheduleSync(): boolean {
        const activityShowOptions = this.getValidActivityShowOptions(this.getValue().ui.activityShowOptions);
        
        return activityShowOptions.find(so => so.value === SHOW_OPTIONS.SHOW_ACTIVE_PANEL)?.state;
    }

    public getActivityShowOptionsSync(): Array<ScheduleShowOption> {
        return this.getValidActivityShowOptions(this.getValue().ui.activityShowOptions);
    }

    public getShowFillFromShowOptionsSync(): boolean {
        const showOptions = this.getShowOptionsSync();

        return showOptions.find(so => so.value === SHOW_OPTIONS.SHOW_FILL)?.state;
    }

    public getScheduleLoadingStateActivityDetailsDialogSync(): boolean {
        return this.getValue().ui.loadingStateActivityDetailsDialog;
    }

    public getScheduleLoadingStateActivityDetailsDialog(): Observable<boolean> {
        return this.select(state => state.ui.loadingStateActivityDetailsDialog);
    }

    public getGroupingOptionType(): Observable<GROUPING_OPTIONS> {
        return this.select(state => state.ui.groupingOptionType);
    }

    public getGroupingOptionTypeSync(): GROUPING_OPTIONS {
        return this.getValue().ui.groupingOptionType;
    }

    public getActivityDetailsForSelectedIdSync(id: number): Array<ScheduleActivity> {
        const activityDetails = this.getValue().activityDialogDetails?.find(act => act.activityId === id);
     
        return activityDetails?.activities;
    }

    public getSelectedDayparts(): Observable<Array<Daypart>> {
        return this.select(state => state.ui.daypartIds).pipe(
            map(ids => {
                const dayParts = this.daypartQuery.getDaypartsWithEndSync();

                return dayParts?.filter(d => ids?.includes(d.id))
            })
        );
    }

    public getSelectedDaypartsSync(): Array<Daypart> {
        const ids = this.getValue().ui.daypartIds;
        const dayParts = this.daypartQuery.getDaypartsWithEndSync();
        
        return dayParts?.filter(d => ids?.includes(d.id))
    }

    public getSelectedActivityTypeIds(): Observable<Array<number>> {
        return this.select(state => state.ui.activityTypeIds);
    }

    public getSelectedActivityTypeIdsSync(): Array<number> {
        return this.getValue().ui.activityTypeIds;
    }

    public updateSortedActivityTypeRootIdsBasedOnSelectedActivityTypeIds(): Array<number> {
        const parentOfSelectedActivityTypeIds = this.getSelectedParentActivityTypesForActivities(this.getSelectedActivityTypeIdsForActivitiesSync(), false, true).map(actType => actType?.id);
        const sortedActivityTypeRootIds = [...this.getSortedActivityTypeRootIdsForActivitiesSync()];
        const currentSortedSet = new Set(sortedActivityTypeRootIds);

        // Add any parent ids from parentOfSelectedActivityTypeIds that are missing in sortedActivityTypeRootIds
        parentOfSelectedActivityTypeIds.forEach((id) => {
            if (!currentSortedSet.has(id)) {
                sortedActivityTypeRootIds.push(id);
            }
        });
    
        // Remove any ids from sortedActivityTypeRootIds that are not in parentOfSelectedActivityTypeIds
        const newSortedActivityTypeRootIds = sortedActivityTypeRootIds.filter((id) =>
            parentOfSelectedActivityTypeIds.includes(id)
        );

        return newSortedActivityTypeRootIds;

    }

    public getAllSelectedActivityTypesFromParentId(rootParentId: number): Array<ActivityTypeWithLevelIndication> {
        const selectedActivityTypeIds = new Set(this.getSelectedActivityTypeIdsForActivitiesSync());
        const allActivityTypes = this.activityTypeQuery.getAll();
        const selectedChildren: Array<ActivityTypeWithLevelIndication> = [];

        if (selectedActivityTypeIds.has(rootParentId)) {
            const parent = allActivityTypes.find(type => type.id === rootParentId);
            selectedChildren.push({...parent, level: 0});
        }
        
        const checkChildren = (parentId: number | null, level: number, forceSelect = false): void => {
            const children = allActivityTypes.filter((activityType) => activityType.parentId === parentId);
        
            for (const child of children) {
                if (forceSelect || selectedActivityTypeIds.has(child.id)) {
                    selectedChildren.push({...child, level});
                }
        
                checkChildren(child.id, level + 1, forceSelect);
            }
        };
        
        checkChildren(rootParentId, 1);

        // If only the parent node is selected, we interpret that as "all children are selected"
        if (selectedChildren.length === 1 && selectedChildren[0].id === rootParentId) {
            checkChildren(rootParentId, 1, true);
        }
        
        return selectedChildren;
    }

    public getSelectedActivityTypeIdsForActivitiesSync(): Array<number> {
        return this.getValue().ui.activityTypeIdsForActivities;
    }

    public getSortedActivityTypeRootIdsForActivitiesSync(): Array<number> {
        return this.getValue().ui.sortedActivityTypeRootIdsForActivities;
    }

    public getSortedActivityTypeRootIdsForActivities(): Observable<Array<number>> {
        return this.select(state => state.ui.sortedActivityTypeRootIdsForActivities);
    }

    public getScheduleLoadingState(): Observable<boolean> {        
        return this.select(state => state.ui.loadingState);
    }

    public getFilterSettingsPanelStatus(): Observable<boolean> {
        return this.select(state => state.ui.filterSettingsPanelOpenState);
    }

    public getFilterSettingsPanelStatusSync(): boolean {
        return this.getValue().ui.filterSettingsPanelOpenState;
    }

    public getHideEmptyRowsStatusSync(): boolean {
        return this.getValue().ui.hideEmptyRows;
    }

    public getSplitterPositionSync(): number {
        return this.getValue().ui.splitterPosition;
    }

    public getScheduleLoadingStateSync(): boolean {
        return this.getValue().ui.loadingState;
    }

    public getScheduleFiltersLoadingState(): Observable<boolean> {
        return combineLatest([
            this.filterSettingQuery.getEntitiesLoadingState(),
            this.organizationUnitQuery.getEntitiesLoadingState(),
            this.resourceTypeQuery.getEntitiesLoadingState(),
            this.resourcePropertyLanguageQuery.getEntitiesLoadingState(),
            this.daypartQuery.getEntitiesLoadingState(),
            this.activityTypeQuery.getEntitiesLoadingState(),
        ]).pipe(
            map(([filterSettings, units, resourceTypes, resourceProperties, dayparts, activityTypes]) =>
                filterSettings || units || resourceTypes || resourceProperties || dayparts || activityTypes
            )
        );
    }

    public getAlphabeticallySortedActivityTypeIdsForActivities(ids: Array<number>): Observable<Array<number>> {
        return this.activityTypeQuery.getAllActivityTypeRoots().pipe(
            // Wait for activity types to be loaded (activity types will never be empty)
            filter(activityTypes => activityTypes.length > 0),
            map(activityTypes => {
                if (ids === undefined || ids.length === 0) {
                    return [];
                }

                // Extract ids from ActivityType entities
                const activityTypeIds = activityTypes.map((activityType) => activityType.id);
    
                // Filter to include only ids present in ids
                const filteredActivityTypeIds = activityTypeIds.filter((id) => ids?.includes(id));
    
                return filteredActivityTypeIds;
            })
        );
    } 

    public getUIState(): Observable<any> {
        return this.select(state => state.ui);
    }

    public getSelectedFilterSettingId(): Observable<number> {
        return this.select(state => state.ui.selectedFilterSettingId);
    }

    public getSelectedMultiselectOrderSync(): Array<MultisortingOption> {
        return this.getValue().ui.savedMultisortingOrder;
    }

    public getSelectedOrganizationUnitIds(): Observable<Array<number>> {
        return this.select(state => state.ui.requestParameters.organizationUnitIds);
    }

    public getApplyFilterOnActivityScheduleState(): Observable<boolean> {
        return this.select(state => state.ui.applyFilterOnActivityScheduleState);
    }

    public getApplyFilterOnActivityScheduleStateSync(): boolean {
        return this.getValue().ui.applyFilterOnActivityScheduleState;
    }

    public getScheduleFiltersValidity(): Observable<boolean> {
        return this.select(state => {
            const ui = state.ui;

            return ui.requestParameters.organizationUnitIds && ui.requestParameters.organizationUnitIds.length > 0 &&
                ui.requestParameters.baseResourceTypeIds && ui.requestParameters.baseResourceTypeIds.length > 0 &&
                ui.activityTypeIds && !!ui.activityTypeIds && ui.activityTypeIdsForActivities && !!ui.activityTypeIdsForActivities;
        });
    }
    
    public getSlotWidth(): Observable<number> {
        return this.select(state => state.ui.slotWidth);
    }

    public getScheduleFilterSettingsFromState(): ScheduleFilterSettings {
        const state = this.getValue();
        const currentSettings: ScheduleFilterSettings = {
            requestParameters: state.ui.requestParameters,
            showOptions: state.ui.showOptions,
            activityShowOptions: state.ui.activityShowOptions,
            groupingOptionType: state.ui.groupingOptionType,
            daterangeType: state.ui.daterangeType,
            daypartIds: state.ui.daypartIds,
            activityTypeIds: state.ui.activityTypeIds,
            activityTypeIdsForActivities: state.ui.activityTypeIdsForActivities,
            sortedActivityTypeRootIdsForActivities: state.ui.sortedActivityTypeRootIdsForActivities,
            applyFilterOnResourceScheduleState: state.ui.applyFilterOnResourceScheduleState,
            applyFilterOnActivityScheduleState: state.ui.applyFilterOnActivityScheduleState,
            allOrganizationUnitsSelectedState: state.ui.allOrganizationUnitsSelectedState,
            savedBaseResourceTypes: state.ui.savedBaseResourceTypes,
            savedExtraResourceTypes: state.ui.savedExtraResourceTypes,
            savedOrganizationUnits: state.ui.savedOrganizationUnits,
            savedResourceProperties: state.ui.savedResourceProperties,
            savedMultisortingOrder: state.ui.savedMultisortingOrder,
            slotWidth: state.ui.slotWidth,
            hideEmptyRows: state.ui.hideEmptyRows,
            splitterPosition: state.ui.splitterPosition
        };

        return currentSettings;
    }

    public getNewScheduleFilterSetting(settingName: string, scheduleSettings?: ScheduleFilterSettings): FilterSetting {
        const settings: ScheduleFilterSettings = scheduleSettings ? scheduleSettings : this.getScheduleFilterSettingsFromState();

        return {
            userId: this.userInfoQuery.getUserIdSync(),
            creationDate: moment(new Date()).toISOString(),
            settings: JSON.stringify(settings),
            settingType: FILTER_SETTING_TYPE.SCHEDULE_OVERVIEW_V2_FILTER_SETTING,
            displayName: settingName
        };
    }

    public getUpdatedScheduleFilterSetting(): FilterSetting {
        const newSettings: ScheduleFilterSettings = this.getScheduleFilterSettingsFromState();
        const currentFilterSetting = this.filterSettingQuery.getSelectedFilterSettingSync();

        return ({ ...currentFilterSetting, settings: JSON.stringify(newSettings) });
    }

    public getFilteredResourcePropertiesSync(): Array<ResourcePropertyLanguage> {
        const resourceProperties: Array<ResourcePropertyLanguage> = this.resourcePropertyLanguageQuery.getResourcePropertiesLanguageSync();
        const selectedResourceProperties = this.getValue().ui.savedResourceProperties
            .map(resourceProperty =>
                resourceProperties.find(rp => rp.resourcePropertyId === resourceProperty.id)
            )
            .filter(prop => prop !== undefined);

        return selectedResourceProperties;
    }

    public getExtraResourceTypesForFiltering(): Observable<Array<ResourceType>> {
        return combineLatest([
            this.resourceTypeQuery.getResourceTypes(),
            this.getSelectedOrganizationUnitIds()
        ]).pipe(
            map(([allResources, filteredOrganizationIds]) =>
                allResources.filter(resource => resource.validOrganizationUnitIds
                    .some(id => filteredOrganizationIds.includes(id))
                )
            )
        );
    }

    public getBaseResourceTypesForFiltering(): Observable<Array<ResourceType>> {
        return combineLatest([
            this.resourceTypeQuery.getResourceTypes(),
            this.getSelectedOrganizationUnitIds()
        ]).pipe(
            map(([allResourceTypes, filteredOrganizationIds]) => {
                if (!this.isFullUser) {
                    return allResourceTypes;
                }

                return allResourceTypes.filter(resourceType => resourceType.validOrganizationUnitIds
                    .some(id => filteredOrganizationIds.includes(id))
                );
            })
        );
    }

    public getFilteredBaseResourceTypesSync(): Array<ResourceType> {
        const filteredBaseResourceIds = this.getValue().ui.requestParameters.baseResourceTypeIds;
        const filteredBaseResourceTypes = this.resourceTypeQuery.getUnsortedResourceTypesSync();
        const resourceTypeMap = new Map(filteredBaseResourceTypes.map(resourceType => [resourceType.id, resourceType]));
        const orderedResourceTypes = filteredBaseResourceIds.map(resourceId => resourceTypeMap.get(resourceId)).filter(resourceType => resourceType !== undefined);
    
        return orderedResourceTypes;
    }

    public getSelectedBaseResourceTypes(): Observable<Array<ResourceType>> {
        return combineLatest([
            this.select(),
            this.resourceTypeQuery.getResourceTypes()
        ]).pipe(map(([state, resourceTypes]) => {
            const baseResourceTypeIds = state.ui.requestParameters.baseResourceTypeIds;
            const sortedResourceTypes = baseResourceTypeIds.map(id => {
                const resourceType = resourceTypes.find(rt => rt.id === id);
                
                return resourceType ? resourceType : null;
            }).filter(rt => rt !== null);

            return sortedResourceTypes;
        }));
    }

    public getSelectedExtraResourceTypesIds(): Observable<Array<number>> {
        return combineLatest([
            this.select(),
            this.resourceTypeQuery.getResourceTypes()
        ]).pipe(map(([state, resourceTypes]) =>
            resourceTypes.filter(resourceType => state.ui.requestParameters.extraResourceTypeIds.includes(resourceType.id)).map(resourceType => resourceType.id)
        ));
    }

    public getActivityTypeSelectedIds(): Observable<Array<number>> {
        return combineLatest([
            this.activityTypeQuery.getMainActivityTypes(),
            this.getSelectedActivityTypeIds(),
        ]).pipe(
            filter(([activityTypes, _]) => activityTypes !== undefined && activityTypes.length > 0),
            map(([activityTypes, ids]) => {
                return ids ? activityTypes.filter(actType => ids.includes(actType.id)).map(actType => actType.id) : [];
            })
        );
    }

    public allOrganizationUnitsSelected(): boolean {
        return this.organizationUnitQuery.getOrganizationUnitsSync().length === this.getSelectedOrganizationUnitIdsSync().length;
    }

    public getActivityTypeSelectedIdsForActivities(): Observable<Array<number>> {
        // TODO: check this code, unless we re-write it, we need to ensure the logic is [] and not undefined
        const getSelectedActivityTypeIdsForActivities = this.select(state => state.ui.activityTypeIdsForActivities).pipe(distinctUntilChanged());

        return combineLatest([
            this.activityTypeQuery.getActivityTypes(),
            getSelectedActivityTypeIdsForActivities,
        ]).pipe(
            filter(([activityTypes, _]) => activityTypes !== undefined && activityTypes.length > 0),
            map(([activityTypes, ids]) => {
                return ids ? activityTypes.filter(actType => ids.includes(actType.id)).map(actType => actType.id) : [];
            })
        );
    }

    public getSelectedOrganizations(): Observable<Array<OrganizationUnit>> {
        const allOrganizationUnitsSelectedState = this.select(state => state.ui.allOrganizationUnitsSelectedState);

        return combineLatest([
            this.organizationUnitQuery.getOrganizationUnits(),
            this.getSelectedOrganizationUnitIds(),
            allOrganizationUnitsSelectedState]
        ).pipe(
            filter(([orgUnits, ids, _]) => orgUnits !== undefined && orgUnits.length > 0 && !!ids ),
            map(([orgUnits, ids, isAllSelected]) => {
                if (isAllSelected) {

                    return orgUnits;
                }

                return orgUnits.filter(unit => ids.includes(unit.id));
            })
        );
    }

    public getSelectedOrganizationUnitIdsSync(): Array<number> {
        return this.getValue().ui.requestParameters.organizationUnitIds;
    }

    public getSelectedOrganizationsSync(): Array<OrganizationUnit> {
        const organizationUnits = this.organizationUnitQuery.getOrganizationUnitsSync();
        const selectedOrganizationUnits = organizationUnits.filter(ou =>
            this.getValue().ui.requestParameters.organizationUnitIds.includes(ou.id)
        );

        return selectedOrganizationUnits;
    }

    public getOrganizationIdsToRetrieveResourceTypesSync(): Array<number> {
        const isFullUser = this.userInfoQuery.getIsFullUserSync();
        if (isFullUser) {
            return this.getSelectedOrganizationUnitIdsSync();
        }
        const selectedUnits = this.getSelectedOrganizationsSync();
        const filteredIdsAndParentIds = JSON.parse(JSON.stringify(this.getSelectedOrganizationUnitIdsSync()));
        selectedUnits.map(unit => {
            if (!filteredIdsAndParentIds.includes(unit.parentId) && unit.parentId) {
                filteredIdsAndParentIds.push(unit.parentId);
            }
        });

        return filteredIdsAndParentIds;
    }

    public currentFilterSettingChangesUnsavedSync(): boolean {
        const currentfilterSetting = this.filterSettingQuery.getSelectedFilterSettingSync();

        if (!currentfilterSetting) {
            return true;
        }
        
        const currentSettingsFromState = JSON.stringify(this.getScheduleFilterSettingsFromState());
        const resourcePropertiesFromState = this.getScheduleFilterSettingsFromState().savedResourceProperties;
        const resourcePropertiesFromCurrentFilterSetting = JSON.parse(currentfilterSetting.settings).savedResourceProperties ?? [];
        const multisortingOrderFromState = this.getScheduleFilterSettingsFromState().savedMultisortingOrder;
        const multisortingOrderFromCurrentFilterSetting = JSON.parse(currentfilterSetting.settings).savedMultisortingOrder ?? [];
        const slotWidthCurrentFilterSetting = JSON.parse(currentfilterSetting.settings).slotWidth;
        const slotWidthFromState = this.getScheduleFilterSettingsFromState().slotWidth;
        const hideEmptyRowsFilterSetting = JSON.parse(currentfilterSetting.settings).hideEmptyRows;
        const hideEmptyRowsFromState = this.getScheduleFilterSettingsFromState().hideEmptyRows;
        const splitterPositionFilterSetting = JSON.parse(currentfilterSetting.settings).splitterPosition;
        const splitterPositionFromState = this.getScheduleFilterSettingsFromState().splitterPosition;
        const sortedActivityTypeRootIdsForActivitiesFilterSetting = JSON.parse(currentfilterSetting.settings).sortedActivityTypeRootIdsForActivities;
        const sortedActivityTypeRootIdsForActivitiesFromState = this.getScheduleFilterSettingsFromState().sortedActivityTypeRootIdsForActivities;

        const showOptionsFromState = this.getValidShowOptions(this.getScheduleFilterSettingsFromState().showOptions);
        const showOptionsFromCurrentFilterSetting = this.getValidShowOptions(JSON.parse(currentfilterSetting.settings).showOptions);
        const actShowOptionsFromState = this.getValidActivityShowOptions(this.getScheduleFilterSettingsFromState().activityShowOptions);
        const actShowOptionsFromCurrentFilterSetting = this.getValidActivityShowOptions(JSON.parse(currentfilterSetting.settings).activityShowOptions);

        return !(this.utilsService.getSortedObjectString(this.removeSettingPropertiesFromScheduleFilter(currentfilterSetting.settings)) ===
            this.utilsService.getSortedObjectString(this.removeSettingPropertiesFromScheduleFilter(currentSettingsFromState)) &&
            Object.keys(resourcePropertiesFromCurrentFilterSetting).length === Object.keys(resourcePropertiesFromState).length &&
            JSON.stringify(resourcePropertiesFromCurrentFilterSetting) === JSON.stringify(resourcePropertiesFromState) && 
            Object.keys(multisortingOrderFromCurrentFilterSetting).length === Object.keys(multisortingOrderFromState).length &&
            JSON.stringify(multisortingOrderFromCurrentFilterSetting) === JSON.stringify(multisortingOrderFromState) &&
            slotWidthCurrentFilterSetting === slotWidthFromState 
            && hideEmptyRowsFilterSetting === hideEmptyRowsFromState &&
            splitterPositionFilterSetting === splitterPositionFromState &&
            Object.keys(showOptionsFromCurrentFilterSetting).length === Object.keys(showOptionsFromState).length &&
            showOptionsFromCurrentFilterSetting.every(option =>
                showOptionsFromState.find(so => so.value === option.value).state === option.state
            ) && Object.keys(actShowOptionsFromCurrentFilterSetting).length === Object.keys(actShowOptionsFromState).length &&
            actShowOptionsFromCurrentFilterSetting.every(option =>
                actShowOptionsFromState.find(so => so.value === option.value).state === option.state
            ) && this.utilsService.arraysHaveTheSameSortOrder(sortedActivityTypeRootIdsForActivitiesFilterSetting, sortedActivityTypeRootIdsForActivitiesFromState));
    }

    // Before comparing, we want to set properties to undefined
    // This is so we don't try to compare settings that might have been removed but still linger in the saved filter
    private removeSettingPropertiesFromScheduleFilter(settings: string): string {
        const parsedSettings = JSON.parse(settings);

        parsedSettings.requestParameters.startDate = undefined;
        parsedSettings.requestParameters.endDate = undefined;
        parsedSettings.requestParameters.languageCode = undefined;
        parsedSettings.hideEmptyRows = undefined;
        parsedSettings.savedMultisortingOrder = undefined;
        parsedSettings.slotWidth = undefined;
        parsedSettings.splitterPosition = undefined;
        parsedSettings.showOptions = undefined;
        parsedSettings.activityShowOptions = undefined;
        parsedSettings.savedBaseResourceTypes = undefined;
        parsedSettings.savedExtraResourceTypes = undefined;
        parsedSettings.savedOrganizationUnits = undefined;
        parsedSettings.allActivityTypesSelectedState = undefined;
        parsedSettings.activityTypeCategoryIds = undefined;
        parsedSettings.activityTypeCategoryIdsForActivitie = undefined;
        parsedSettings.allActivityTypesSelectedForActivitiesStat = undefined;
        parsedSettings.activitiesOrganizationUnitIdsForResources = undefined;
        parsedSettings.activitiesOrganizationUnitIdsForActivities = undefined;
        parsedSettings.activitiesAllOrganizationUnitIdsSelectedForResourcesState = undefined;
        parsedSettings.activitiesAllOrganizationUnitIdsSelectedForActivitiesState = undefined;

        return JSON.stringify(parsedSettings);
    }

    private getValidShowOptions(showOptions: Array<ScheduleShowOption>): Array<ScheduleShowOption> {
        const validShowOptions: Array<ScheduleShowOption> = this.scheduleHelperService.getInitialShowOptions(this.isFullUser).map(initialShowOption => {
            const matchingShowOption = showOptions.find(opt => opt.value === initialShowOption.value);

            if (matchingShowOption) {
                initialShowOption.state = matchingShowOption.state;
            }

            return initialShowOption;
        });

        return validShowOptions;
    }

    private getValidActivityShowOptions(actShowOptions: Array<ScheduleShowOption>): Array<ScheduleShowOption> {
        const validActShowOptions: Array<ScheduleShowOption> = this.scheduleHelperService.getInitialActivityShowOptions(this.isFullUser).map(initialActivityShowOption => {
            const matchingShowOption = actShowOptions?.find(opt => opt.value === initialActivityShowOption.value);
            if (matchingShowOption) {
                initialActivityShowOption.state = matchingShowOption.state;
            }

            return initialActivityShowOption;
        });

        return validActShowOptions;
    }
}
