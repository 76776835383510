// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    local: false,
    acceptance: false,

    useAngularScreens: 'false',
    enableTestBorderHiding: 'false',
    authMode: 'Auth0',

    omrpApiUrl: 'https://api-omrp-acc.ortechealth.com',

    enableApplicationInsights: 'true',

    auth0Constants: {
        clientId: 'Q1NmVHQmqv1ARus8kn7Ax9ULgYa4muUL',
        domain: 'logiqcare-test.eu.auth0.com',
        audience: 'https://logiqcare-test.eu.auth0.com/userinfo',
        redirectUri: window.location.protocol + '//' + window.location.host + '/auth0Callback.html?singleSpaAppPrefix=angularomrp',
        responseType: 'code',
        scope: 'openid offline_access profile email',
    },

    appInsights: {
        instrumentationKey: 'f294061d-c244-42b6-a160-0393521ae4f6'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
